<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <h1>{{ attr_copy['name']}}</h1>
    <actor-role-index
      :filtering="actor_filter"
      :refresh="refresh"
      @refreshed="refreshed"
    >
    </actor-role-index>
    <actor-role-create
      :ams_template="actor_role_template"
      @takeAction="takeAction"
      @noAction="noAction"
      :reroute="false"
    />
    <b-button block variant="primary" @click="callDone">Done</b-button>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import ActorRoleIndex from './ActorRoleIndex.vue'
import ActorRoleCreate from './ActorRoleCreate.vue'
import {http_v1} from '@/axios'
import {amsSchema} from '@/schema'

export default {
  components: {
    VuexBreadcrumb,
    ActorRoleIndex,
    ActorRoleCreate
  },
  props: {
    id: {
      Type: String
    }
  },
  data() {
    return {
      attr_copy: {},
      actor_role_template: {},
      actor_filter: {
        work_group_ids: this.id
      },
      refresh: false,
    }
  },
  methods: {
    startTrail() {
      let nav = {
        name: 'WorkGroupUpdateScreen', 
        params: {
          id: this.id
        }
      }
      this.$store.dispatch('query_path/start_trail', {
        name: this.attr_copy['name'] + ' WorkGroup',
        navigation: nav
      })
    },
    takeAction() {
      this.refresh = true
      this.setActorRole()
    },
    noAction() {
      this.setActorRole()
    },
    refreshed() {
      this.refresh = false
    },
    callDone() {
      this.$router.go(-1)
    },
    setActorRole() {
      let actor_role_ams = amsSchema.find( 
        el =>  
        el.type === 'actor-roles'
      )
      actor_role_ams.attributes['work-group-id'] = this.id
      this.actor_role_template = {...actor_role_ams}
    }
  },
  mounted () {
    http_v1.get('api/admin/v1/work_groups/' + this.id, {
    }).then(response => {
      if( response.data) {
        this.attr_copy = Object.assign( {}, response.data.data.attributes)
        this.setActorRole()
        this.startTrail()
      }
    }).catch(error => {
      if( error.response && error.response.data) {
        var message = error.response.data.message || JSON.stringify(error.response.data)
        if (error.response.status === 500 ) message = "500 Server Error"
        this.$bvToast.toast(message, {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: true
        })
      }
    })
  }
}
</script>
